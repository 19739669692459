import React from 'react';
import PropTypes from 'prop-types';

const PAYMENT_TYPES = { cash: 'cash', monthly: 'monthly' };

const PaymentSwitch = ({ selected, onChange }) => {
  return (
    <div className="payment-switch__wrapper">
      <div className={`payment-switch payment-switch--${selected}`}>
        <label>
          <input
            checked={selected === PAYMENT_TYPES.monthly}
            className="ch-reader"
            name="payment_type"
            onChange={() => onChange(PAYMENT_TYPES.monthly)}
            type="radio"
            value="monthly"
          />
          Monthly payment
        </label>

        <label>
          <input
            checked={selected === PAYMENT_TYPES.cash}
            className="ch-reader"
            name="payment_type"
            onChange={() => onChange(PAYMENT_TYPES.cash)}
            type="radio"
            value="cash"
          />
          Cash price
        </label>

        <span className="payment-switch__indicator" />
      </div>
    </div>
  );
};

export default PaymentSwitch;

PaymentSwitch.defaultProps = {
  selected: PAYMENT_TYPES.monthly,
};

PaymentSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOf([PAYMENT_TYPES.cash, PAYMENT_TYPES.monthly]),
};
