import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import SortOrder from './sortOrder';
import { useWindowEventListener } from '@lib/hooks';
import classNames from 'classnames';
import { useTagVisibilityToggle } from './hooks/useTagVisibilityToggle';
import { HeaderFilterContext } from './vehicleSearchContainer';
import SearchTags from './searchTags';
import useStockAlertUrlWithFilters from '@javascript/hooks/useStockAlertUrlWithFilters';

const VehicleSearchHeader = ({
  appliedFilters,
  invalidSearch,
  onFilterCriteriaUpdate,
  onRemoveAllTags,
  removeFilterTag,
  searchTags,
  searchTitle,
}) => {
  const initialIsSticky = true;
  const [isSticky, setSticky] = useState(initialIsSticky);
  const searchHeaderRef = useRef(null);
  const handleScroll = () => {
    !searchHeaderRef.current
      ? setSticky(false)
      : setSticky(searchHeaderRef.current.getBoundingClientRect().top <= 0);
  };

  const searchTagListRef = useRef(null);
  const removeFiltersButtonRef = useRef(null);

  useEffect(() => {
    handleScroll();
  }, []);

  const {
    initialFilterHeight,
    isShowingMoreSearchTags,
    isTagsToggleVisible,
    setIsShowingMoreSearchTags,
    setIsTagsToggleVisible,
    setVisibleTagCount,
    visibleTagCount,
  } = useContext(HeaderFilterContext);

  const toggleShowMoreTags = useTagVisibilityToggle({
    searchTags,
    searchTagListRef,
    removeFiltersButtonRef,
    isShowingMoreSearchTags,
    setIsShowingMoreSearchTags,
    setIsTagsToggleVisible,
    setVisibleTagCount,
    initialFilterHeight,
  });

  useWindowEventListener('scroll', handleScroll);

  const openSearchFilters = () =>
    EventEmitter.emit(EVENT_TYPES.openModal, 'filters');

  const renderTitle = () => <h1 className="ch-h5 ch-mb--0">{searchTitle}</h1>;

  const isMultipleFilterLines =
    searchTagListRef.current?.offsetHeight > initialFilterHeight.current;

  const stockAlertUrl = useStockAlertUrlWithFilters(appliedFilters);

  return (
    <>
      <div className="search-header__main-wrapper" ref={searchHeaderRef}>
        <div
          className={`search-header__main-header ${isSticky ? 'sticky' : ''}`}
        >
          <div className="search-header__actions">
            <SortOrder
              appliedFilters={appliedFilters}
              onFilterCriteriaUpdate={onFilterCriteriaUpdate}
            />
            <div className="ch-display--none sm:ch-display--block">
              {renderTitle()}
            </div>
            <button
              className="ch-btn ch-ml--auto search-header__filters-btn"
              onClick={openSearchFilters}
              type="button"
            >
              Filter results
              {searchTags.length > 0 && (
                <span className="ch-ml--1 ch-color--ac-blue">{` (${searchTags.length})`}</span>
              )}
            </button>
          </div>
        </div>
      </div>

      <div
        className={classNames('search-header__sub-header ch-pv--2', {
          'sm:ch-bt--1 ch-bc--grey-3 sm:ch-pt--3 sm:ch-mt--2':
            searchTags.length > 0,
        })}
      >
        <div className="ch-container">
          <div className="sm:ch-display--none">{renderTitle()}</div>
          {searchTags.length > 0 && (
            <div className="searchtags">
              <SearchTags
                invalidSearch={invalidSearch}
                isMultipleFilterLines={isMultipleFilterLines}
                isShowingMoreSearchTags={isShowingMoreSearchTags}
                isTagsToggleVisible={isTagsToggleVisible}
                removeFilterTag={removeFilterTag}
                removeFiltersButtonRef={removeFiltersButtonRef}
                searchTagListRef={searchTagListRef}
                searchTags={searchTags}
                toggleShowMoreTags={toggleShowMoreTags}
                visibleTagCount={visibleTagCount}
              />

              <div className="sm:ch-ml--auto sm:ch-display--flex ch-flex--none">
                <button
                  className="ch-btn--link ch-pa--0 ch-ml--2 js-off--hide ch-display--none sm:ch-display--block"
                  data-test="remove-all-filters"
                  onClick={onRemoveAllTags}
                  ref={removeFiltersButtonRef}
                  type="button"
                >
                  Remove all filters
                </button>
                <div className="ch-mt--2 sm:ch-mt--0 sm:ch-ml--2 ch-display--flex ch-flex--none">
                  <a
                    className="ch-btn ch-btn--sm ch-pa--2 sm:ch-ml--auto ch-width--12 sm:ch-width--auto js-off--hide stock-alert__cta"
                    data-gtm-track="interaction"
                    data-gtm-track-action="Opened stock alerts wizard"
                    href={stockAlertUrl}
                  >
                    Create a stock alert
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VehicleSearchHeader;

VehicleSearchHeader.propTypes = {
  appliedFilters: PropTypes.object,
  invalidSearch: PropTypes.bool,
  onFilterCriteriaUpdate: PropTypes.func,
  onRemoveAllTags: PropTypes.func,
  removeFilterTag: PropTypes.func,
  searchTags: PropTypes.array,
  searchTitle: PropTypes.string,
};
