import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';

import AccountLoading from '../account/loading';
import CompareButton from '../vehicle_comparison/compareButton';
import ComparePanel from '../vehicle_comparison/comparePanel';
import EmptyShortlist from './emptyShortlist';
import ShareShortlistButton from './shareShortlistButton';
import ShortlistError from './shortlistError';
import ShortlistHeader from './shortlistHeader';
import ShortlistLoginCard from './shortlistLoginCard';
import VehicleResult from '../vehicle_search/vehicleResult';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

import useIsAuthenticated from '@lib/hooks/useIsAuthenticated';
import useShortlist from './shortlistStore';
import useVehicleResults from '@lib/hooks/useVehiclesByStockRef';
import { useComparison } from '../vehicle_comparison/hooks';
import Auth from '@javascript/lib/auth';

import type { WithExperiments } from '@javascript/types/Experiment';
import ShortlistRemoveAllModal from './shortlistRemoveAllModal';

interface ShortlistProps {
  authenticityToken: string;
  sharedUrl: string;
}

const Shortlist = ({
  authenticityToken,
  experiments,
  sharedUrl,
}: ShortlistProps & WithExperiments) => {
  const { isLoggedIn, loading: authLoading } = useIsAuthenticated();

  const {
    isLoggedIn: nativeAppLoggedIn,
    isNativeApp,
    shortlistEnabled,
  } = acMobileFeatures();

  //shortlistEnabled also includes a check for a native app user
  const usingApiStorage =
    isLoggedIn || (shortlistEnabled() && nativeAppLoggedIn());

  // Native app users without the shortlist functionality should not see log in messages
  // login message should appear when user is either
  // - a web user (not a native app) and not logged in, OR
  // - a native app user who has the shortlist enabled, and is not logged in
  const showLoginPrompts =
    (!isNativeApp() && !isLoggedIn) ||
    (shortlistEnabled() && !nativeAppLoggedIn());

  const {
    store: { shortlist, reset, replace },
    hasHydrated,
  } = useShortlist();

  const { error, loading, vehicles } = useVehicleResults(shortlist, replace);
  const { clearAll, handleComparison, stockRefs } = useComparison();

  const login = () =>
    new Auth()
      .initializeAuthClient(window.authOptions)
      .then((client) => client.login());

  if (authLoading || !hasHydrated) return <AccountLoading />;

  if (error) return <ShortlistError />;

  if (shortlist.length === 0)
    return (
      <EmptyShortlist
        login={login}
        showLoginButton={!isNativeApp() && !isLoggedIn}
        showLoginMessage={showLoginPrompts}
      />
    );

  const shortlistedVehicles = vehicles.filter((vehicle) =>
    shortlist.includes(vehicle.stockReference)
  );

  const openModal = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, 'removeAllShortlist');
  };

  return (
    <>
      <div className="ac-shortlist ch-container ch-ph--3">
        <ShortlistHeader
          shortlist={shortlist}
          usingApiStorage={usingApiStorage}
        />
        <div className="ch-display--flex ch-justify-content--between">
          <ShareShortlistButton sharedUrl={sharedUrl} shortlist={shortlist} />
          <button
            className="ac-shortlist__remove-all-link ch-color--ac-red ch-btn ch-fs--3 ch-pr--0 ch-btn--link"
            data-gtm-track="click_cta"
            data-gtm-track-label="Remove all shortlisted vehicles"
            onClick={openModal}
            type="button"
          >
            Remove all
          </button>
        </div>
        <hr className="ch-mv--3 ch-bg--grey-3" />
        {loading ? (
          <div className="ac-results">
            {shortlist.map((stockRef) => (
              <div
                className="ac-result ch-skeleton ac-result__skeleton"
                key={stockRef}
              />
            ))}
          </div>
        ) : (
          <>
            {showLoginPrompts && (
              <ShortlistLoginCard isAppUser={isNativeApp()} login={login} />
            )}
            <div className="ac-animation--fade-in ac-results">
              {shortlistedVehicles.map((result) => (
                <VehicleResult
                  {...result}
                  authenticityToken={authenticityToken}
                  compareButton={
                    <CompareButton
                      handleChange={handleComparison}
                      stockReference={result.stockReference}
                      stockRefs={stockRefs}
                    />
                  }
                  experiments={experiments}
                  key={result.stockReference}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <ShortlistRemoveAllModal
        reset={reset}
        usingApiStorage={usingApiStorage}
      />
      {stockRefs.length > 0 && (
        <ComparePanel clearAll={clearAll} stockRefs={stockRefs} />
      )}
    </>
  );
};

export default Shortlist;
