const ShortlistHeader = ({
  shortlist,
  usingApiStorage,
}: {
  shortlist: string[];
  usingApiStorage: boolean;
}): JSX.Element => {
  const vehicleOrVehicles = shortlist.length > 1 ? 'vehicles' : 'vehicle';

  return (
    <h1 className="ch-fs--5 sm:ch-fs--6 ch-mv--4">
      {usingApiStorage
        ? `You have ${shortlist.length} saved ${vehicleOrVehicles}`
        : `${shortlist.length} ${vehicleOrVehicles} in your shortlist`}
    </h1>
  );
};

export default ShortlistHeader;
