import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FilterButton, FilterToggle } from '@components/search_filters/units';
import { FILTERS_SCHEMA } from '@components/search_filters/constants';
import {
  selectedEngineSizeToString,
  selectedLocationToString,
  selectedMakeToString,
  selectedMakeModelsToString,
  selectedMakeModelsTrimsToString,
  selectedMPGToString,
  selectedPriceToString,
  selectedRoadTaxToString,
  selectedKeywordsToString,
  selectedToUpToString,
  selectedValueToString,
} from '@components/search_filters/helpers/selectedOptionsDisplay';

const VehicleSearchFilters = ({
  appliedFilters,
  count,
  loading,
  onFilterClick,
  onFiltersClose,
  onToggleChange,
  resetFilters,
}) => {
  const getFilterButtonProps = (filter) => {
    const { id, name } = filter;
    const selectedOptions = selectedValueToString(appliedFilters, id);
    return {
      id,
      name,
      onClick: () => onFilterClick(filter),
      selectedOptions,
    };
  };

  const disableModel = () => {
    return Object.keys(appliedFilters.makeModel).length === 0;
  };

  const disableTrim = () => {
    if (disableModel()) {
      return true;
    }

    return !Object.keys(appliedFilters.makeModel)
      .map((make) => {
        return Object.keys(appliedFilters.makeModel[make].models).length !== 0;
      })
      .includes(true);
  };

  const isTrimDisabled = disableTrim();

  return (
    <div className="filters-list">
      <div className="filter__header">
        <button
          className="ch-btn filter__header-back"
          onClick={onFiltersClose}
          type="button"
        >
          Back
        </button>
        <div className="ch-pa--2 ch-display--flex ch-align-items--center ch-justify-content--between">
          <h3 className="ch-fs--4 ch-mb--0">Filter results</h3>
          <button
            className="ch-btn ch-btn--sm ch-color--ac-red"
            onClick={resetFilters}
            type="button"
          >
            Clear filters
          </button>
        </div>
      </div>
      <div className="filter__body">
        <FilterButton
          id="make"
          name="Makes"
          onClick={() => onFilterClick(FILTERS_SCHEMA.make)}
          selectedOptions={selectedMakeToString(appliedFilters.makeModel)}
        />
        <FilterButton
          disabled={disableModel()}
          id="model"
          name="Models"
          onClick={() => onFilterClick(FILTERS_SCHEMA.model)}
          selectedOptions={selectedMakeModelsToString(appliedFilters.makeModel)}
        />
        <FilterButton
          disabled={isTrimDisabled}
          id="trim"
          name="Model variants"
          onClick={() => onFilterClick(FILTERS_SCHEMA.trim)}
          selectedOptions={
            isTrimDisabled
              ? ''
              : selectedMakeModelsTrimsToString(appliedFilters.makeModel)
          }
        />
        <FilterButton
          id="location"
          name="Location"
          onClick={() => onFilterClick(FILTERS_SCHEMA.location)}
          {...selectedLocationToString(appliedFilters)}
        />
        <FilterButton
          id="price"
          name="Price"
          onClick={() => onFilterClick(FILTERS_SCHEMA.price)}
          selectedOptions={selectedPriceToString(appliedFilters)}
        />
        <FilterToggle
          checked={appliedFilters.photosOnly}
          id="photosOnly"
          name="Hide cars without images"
          onChange={(id, value) => onToggleChange({ [id]: value })}
        />
        <FilterToggle
          checked={appliedFilters.unreservedOnly}
          id="unreservedOnly"
          name="Hide reserved cars"
          onChange={(id, value) => onToggleChange({ [id]: value })}
        />
        <FilterButton
          id="age"
          name="Age"
          onClick={() => onFilterClick(FILTERS_SCHEMA.age)}
          {...selectedToUpToString(appliedFilters.age, 'year%s% old')}
        />
        <FilterButton {...getFilterButtonProps(FILTERS_SCHEMA.bodyType)} />
        <FilterButton {...getFilterButtonProps(FILTERS_SCHEMA.searchType)} />
        <FilterButton {...getFilterButtonProps(FILTERS_SCHEMA.driveTrain)} />
        <FilterButton {...getFilterButtonProps(FILTERS_SCHEMA.colour)} />
        <FilterButton
          id="door"
          name="Doors"
          onClick={() => onFilterClick(FILTERS_SCHEMA.doors)}
          selectedOptions={selectedValueToString(
            appliedFilters,
            FILTERS_SCHEMA.doors.id,
            'doors'
          )}
        />
        <FilterButton
          id="engine"
          name="Engine size"
          onClick={() => onFilterClick(FILTERS_SCHEMA.engine)}
          selectedOptions={selectedEngineSizeToString(appliedFilters)}
        />
        <FilterButton
          id="mpg"
          name="Fuel consumption / MPG"
          onClick={() => onFilterClick(FILTERS_SCHEMA.mpg)}
          selectedOptions={selectedMPGToString(appliedFilters)}
        />
        <FilterButton {...getFilterButtonProps(FILTERS_SCHEMA.fuelType)} />
        <FilterButton {...getFilterButtonProps(FILTERS_SCHEMA.transmission)} />
        <FilterButton
          id="keywords"
          name="Keywords"
          onClick={() => onFilterClick(FILTERS_SCHEMA.keywords)}
          selectedOptions={selectedKeywordsToString(appliedFilters)}
        />
        <FilterButton
          id="mileage"
          name="Mileage"
          onClick={() => onFilterClick(FILTERS_SCHEMA.mileage)}
          {...selectedToUpToString(appliedFilters.mileage, 'miles')}
        />
        <FilterButton
          id="roadtaxCost"
          name="Road tax"
          onClick={() => onFilterClick(FILTERS_SCHEMA.roadtaxCost)}
          selectedOptions={selectedRoadTaxToString(appliedFilters)}
        />
        <FilterButton
          {...getFilterButtonProps(FILTERS_SCHEMA.seats)}
          name="Seats"
          selectedOptions={selectedValueToString(
            appliedFilters,
            FILTERS_SCHEMA.seats.id,
            'seats'
          )}
        />
      </div>

      <div className="filter__footer">
        <button
          className={classNames('ch-btn ch-btn--block ch-btn--success', {
            'ac-btn--loading': loading,
          })}
          onClick={onFiltersClose}
          type="button"
        >
          Show {count} results
        </button>
      </div>
    </div>
  );
};

export default VehicleSearchFilters;

VehicleSearchFilters.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
  count: PropTypes.number,
  loading: PropTypes.bool,
  onFilterClick: PropTypes.func.isRequired,
  onFiltersClose: PropTypes.func.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func,
};
