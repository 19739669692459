import { useState, useEffect } from 'react';
import { fetchVehiclesByStockReference } from '@lib/requests/stockReference';
import type { AsyncVehicles } from '@javascript/types/AsyncResult';
import type { Vehicle } from '@javascript/types/Vehicle';

export default function useVehiclesByStockRef(
  stockRefs: string[],
  updateStockRefs?: (stockRefs: string[]) => void
): AsyncVehicles {
  const [state, setState] = useState<AsyncVehicles>({
    error: null,
    loading: false,
    vehicles: [],
  });

  useEffect(() => {
    if (stockRefs.length === 0) {
      return setState({ vehicles: [], loading: false, error: null });
    }

    if (
      stockRefs.length < state.vehicles.length ||
      stockRefs.length === state.vehicles.length
    )
      return;

    if (stockRefs.length > 0) {
      setState((oldState: AsyncVehicles) => ({
        ...oldState,
        loading: true,
      }));

      try {
        fetchVehiclesByStockReference(stockRefs).then((res) => {
          setState({ vehicles: res.body, loading: false, error: null });

          if (res.body.length !== stockRefs.length && !!updateStockRefs) {
            updateStockRefs(
              res.body.map((vehicle: Vehicle) => vehicle.stockReference)
            );
          }
        });
      } catch (error) {
        setState({ vehicles: [], loading: false, error: error as Error });
      }
    }
  }, [stockRefs]);

  return state;
}
