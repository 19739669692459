import Analytics from '@lib/analytics';

export const getPostcodePrefix = (postcode) =>
  postcode ? postcode.split(' ')[0] : '';

export const getPostcode = (place) => {
  const postcode = place.address_components.find(
    (data) =>
      data.types[0] === 'postal_code_prefix' || data.types[0] === 'postal_code'
  );
  return postcode ? postcode.long_name : '';
};

export const getTownName = (place) => {
  const town = place.address_components.find(
    (data) => data.types[0] === 'postal_town'
  );
  return town ? town.long_name : '';
};

export const buildAnalyticsData = (place) => {
  const postcode = getPostcode(place);
  return {
    searchPostalCode: getPostcodePrefix(postcode),
    searchPostalTown: getTownName(place),
  };
};

const addToAnalytics = (place) => {
  if (!place.address_components) return;

  const data = buildAnalyticsData(place);

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      Analytics.dataPush(key, data[key]);
    }
  }
};

export default addToAnalytics;
