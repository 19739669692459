import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FilterContainer,
  FilterOption,
} from '@components/search_filters/units';
import { ValidPostcode } from '@lib/regularExpressions';
import GeolocationButton from '@components/application/input/locationFinder/geolocationButton';
import { requestScriptLoad } from '@components/helpers/externalScriptHelper';

const Location = ({ availableOptions, onFilterUpdate, selected }) => {
  const [postcode, setPostcode] = useState(selected.location);
  const [distance, setDistance] = useState(selected.distance);
  const [error, setError] = useState(false);
  const [showClickAndCollectOptions, setShowClickAndCollectOptions] = useState(
    selected.showClickAndCollectOptions
  );

  useEffect(() => {
    !window.google && requestScriptLoad('google');
  }, []);

  const checkIfBrowserSupportsGeolocation = () => {
    return navigator.geolocation && navigator.geolocation.getCurrentPosition;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    if (showClickAndCollectOptions && !postcode) return setError(true);
    if (postcode === '' || ValidPostcode.test(postcode)) {
      onFilterUpdate({
        location: postcode.toUpperCase(),
        distance,
        showClickAndCollectOptions,
      });
    } else {
      setError(true);
    }
  };

  const updateDistance = (distance) => setDistance(distance);

  const toggleClickAndCollectOptions = () => {
    if (!showClickAndCollectOptions) setDistance('');
    setShowClickAndCollectOptions(!showClickAndCollectOptions);
  };

  return (
    <FilterContainer handleClick={handleSubmit} title={'Select a location'}>
      <form
        className={`ch-form__group ${error && 'ch-form__group--error'}`}
        onSubmit={handleSubmit}
      >
        <h4 className="ch-mt--2">Set your location</h4>
        {error && (
          <span className="ch-form__control-validation">
            Please enter a full, valid postcode.
          </span>
        )}
        <div className="ac-location__group">
          {checkIfBrowserSupportsGeolocation() && (
            <GeolocationButton
              onSuccess={({ location }) => setPostcode(location)}
              source="vehicle search"
            />
          )}
          <input
            className={`ch-form__control ${
              checkIfBrowserSupportsGeolocation() && 'ch-pl--5'
            }`}
            id="location-postcode"
            onChange={(e) => setPostcode(e.target.value)}
            placeholder="Enter your postcode"
            type="text"
            value={postcode}
          />
        </div>
        <input
          checked={showClickAndCollectOptions}
          className="ch-checkbox"
          id="click-collect-toggle"
          name="click-collect-toggle"
          onChange={toggleClickAndCollectOptions}
          type="checkbox"
        />
        <label
          className="ch-checkbox__label ch-checkbox__label--primary ch-checkbox__label--hide-checkbox ch-display--flex ch-justify-content--between ch-mt--2"
          htmlFor="click-collect-toggle"
        >
          <p className="ch-text--normal ch-mb--0 ch-fs--2 sm:ch-fs--3">
            Show <b className="ch-text--bold">Click and Collect</b> options
          </p>
          <span className="ch-toggle ch-ml--2"></span>
        </label>
        <h4 className="ch-mt--4">Show me cars within</h4>
        {availableOptions.map(([displayLabel, value]) => (
          <FilterOption
            autoFocus={distance === value}
            disabled={
              !ValidPostcode.test(postcode) || showClickAndCollectOptions
            }
            key={`distance_${value}`}
            label={displayLabel}
            name="distance"
            onChange={updateDistance}
            selected={distance === value}
            type="radio"
            value={value}
          />
        ))}
        <button className="ch-reader" type="submit">
          Done
        </button>
      </form>
    </FilterContainer>
  );
};

export default Location;

Location.propTypes = {
  availableOptions: PropTypes.array,
  onFilterUpdate: PropTypes.func.isRequired,
  selected: PropTypes.object,
};
