import React from 'react';
import PropTypes from 'prop-types';

const VehicleComparisonCta = ({ isEligibleForDealBuilder, stockReference }) => {
  return (
    <div
      className="compare-width ch-flex--none ch-pv--2 ch-ph--1 ch-text--center"
      key={stockReference}
    >
      {isEligibleForDealBuilder ? (
        <a
          className="ch-btn ch-btn--primary ch-btn--sm ch-width--12"
          data-gtm-track="click_cta"
          data-gtm-track-label="Reserve comparison vehicle"
          href={`/dealbuilder/${stockReference}?reserve_now=true`}
          rel="nofollow"
        >
          Reserve for £99
        </a>
      ) : (
        <a
          className="ch-btn ch-btn--secondary ch-btn--sm ch-width--12"
          data-gtm-track="click_cta"
          data-gtm-track-label="Enquire on comparison vehicle"
          href={`/contact-us/vehicle-enquiry/${stockReference}`}
          rel="nofollow"
        >
          Enquire now
        </a>
      )}
    </div>
  );
};

VehicleComparisonCta.propTypes = {
  isEligibleForDealBuilder: PropTypes.bool,
  stockReference: PropTypes.string,
};

export default VehicleComparisonCta;
