import React from 'react';
import PropTypes from 'prop-types';

const FilterToggle = ({ checked, id, name, onChange }) => {
  return (
    <label className={`filter-toggle ${checked && 'filter-toggle--checked'}`}>
      <input
        checked={checked.length > 0 && JSON.parse(checked)}
        className="ch-reader"
        id={id}
        onChange={() => onChange(id, !checked)}
        type="checkbox"
      />
      {name}
      <span className="filter-toggle__indicator">
        <span className="filter-toggle__indicator-inner" />
      </span>
    </label>
  );
};

export default FilterToggle;

FilterToggle.defaultProps = {
  checked: false,
};

FilterToggle.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
