import classNames from 'classnames';

type FeatureOptionProps = {
  name: string;
  onChange: (value: string) => void;
  selected: boolean;
  value: string;
};

const FeatureOption = ({
  name,
  onChange,
  selected,
  value,
}: FeatureOptionProps) => {
  return (
    <label
      className={classNames('feature-option', {
        'feature-option--checked': selected,
      })}
    >
      <input
        checked={selected}
        className="ch-reader"
        name={name}
        onChange={() => onChange(value)}
        type="checkbox"
        value={value}
      />
      {value}
    </label>
  );
};

export default FeatureOption;
