import EventEmitter, { EVENT_TYPES } from '@javascript/lib/eventEmitter';
import LogoutModal from './logoutModal';
import Modal from '@components/application/modal';
import BackButton from '../application/backButton';
import useIsAuthenticated from '@javascript/lib/hooks/useIsAuthenticated';
import Auth from '@javascript/lib/auth';
import Loading from './loading';

const MyAccount = () => {
  const openLogoutModal = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, `logout`);
  };

  const { isLoggedIn, loading } = useIsAuthenticated();

  if (loading && !isLoggedIn) {
    return <Loading />;
  }
  if (!isLoggedIn) {
    new Auth()
      .initializeAuthClient(window.authOptions)
      .then((client) => client.login());
    return <Loading />;
  }

  return (
    <>
      <div className="ch-container ch-mt--3">
        <BackButton />
        <div className="ac-account">
          <div className="ch-ba--1 ch-rounded--md ch-bc--white ch-bg--white ch-ph--3 ch-mv--3 ch-display--flex ch-flex-flow--column-nowrap">
            <a
              className="ch-fs--3 ch-color--ac-black ch-pv--3 ch-text-decoration--none help-link"
              data-gtm-track="interaction"
              data-gtm-track-action="Clicks link"
              data-gtm-track-label="Need Help? - My Account"
              href="/help-topics"
            >
              Need help?
            </a>
            <hr className="ch-bg--grey-3"></hr>
            <a
              className="ch-fs--3 ch-color--ac-black ch-pv--3 ch-text-decoration--none help-link"
              href="/cookies"
            >
              Privacy policy
            </a>
            <hr className="ch-bg--grey-3"></hr>
            <a
              className="ch-fs--3 ch-color--ac-black ch-pv--3 ch-text-decoration--none help-link"
              href="https://forms.office.com/e/66gdPZJABz"
            >
              Give feedback
            </a>
          </div>
          <button
            className="ch-ba--1 ch-rounded--md ch-width--12 ch-bc--white ch-bg--white ch-pv--3 ch-ph--2 ch-mb--3 ch-fs--3 ch-fw--500 ch-text--left log-out"
            data-gtm-track="click_cta"
            data-gtm-track-label="Logout"
            onClick={openLogoutModal}
            type="button"
          >
            Log out
          </button>
        </div>
      </div>
      <Modal hideClose id="logout">
        <LogoutModal />
      </Modal>
    </>
  );
};

export default MyAccount;
