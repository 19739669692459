import Auth from '@javascript/lib/auth';
import { AsyncResult } from '@javascript/types/AsyncResult';
import { useState, useEffect } from 'react';

interface AuthenticationCheck extends AsyncResult {
  isLoggedIn: boolean;
}

export default function useIsAuthenticated(): AuthenticationCheck {
  const [state, setState] = useState<AuthenticationCheck>({
    isLoggedIn: false,
    loading: true,
    error: null,
  });

  useEffect(() => {
    (async () => {
      const auth = await new Auth().initializeAuthClient(window.authOptions);

      const isAuthenticated = await auth.isAuthenticated();

      setState({
        loading: false,
        error: null,
        isLoggedIn: isAuthenticated,
      });
    })();
  }, []);

  return state;
}
