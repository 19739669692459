import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FilterOption = ({
  autoFocus,
  children,
  disabled,
  label,
  name,
  onChange,
  selected,
  type,
  value,
}) => {
  return (
    <label
      className={classNames('filter-option', {
        'filter-option--checked': selected,
        'filter-option--disabled': disabled,
      })}
    >
      <input
        autoFocus={autoFocus}
        checked={selected}
        className="ch-reader"
        disabled={disabled}
        name={name}
        onChange={() => onChange(value)}
        type={type}
        value={value}
      />
      {children}
      {label}
      <span className={`filter-option__${type}`} />
    </label>
  );
};

export default FilterOption;

FilterOption.propTypes = {
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
