import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import VehicleSearchFilters from './vehicleSearchFilters';
import { isEqual } from './helpers/vehicleSearchHelpers';
import { FILTER_TYPES } from '@components/search_filters/constants';
import {
  ColourSelect,
  EngineSelect,
  Keywords,
  Location,
  MakeSelect,
  ModelSelect,
  MultiSelect,
  PriceSelect,
  SingleSelect,
  TrimSelect,
} from '@components/search_filters/filters';

export const FILTER_BY_TYPE = {
  [FILTER_TYPES.colours]: ColourSelect,
  [FILTER_TYPES.engine]: EngineSelect,
  [FILTER_TYPES.keywords]: Keywords,
  [FILTER_TYPES.location]: Location,
  [FILTER_TYPES.make]: MakeSelect,
  [FILTER_TYPES.model]: ModelSelect,
  [FILTER_TYPES.multi]: MultiSelect,
  [FILTER_TYPES.price]: PriceSelect,
  [FILTER_TYPES.single]: SingleSelect,
  [FILTER_TYPES.trim]: TrimSelect,
};
const EMPTY_VALUE = '';

const VehicleSearchFiltersContainer = (props) => {
  const {
    appliedFilters,
    availableOptions,
    count,
    experiments,
    loading,
    onFilterCriteriaUpdate,
    resetFilters,
  } = props;

  const [activeFilter, setActiveFilter] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  const openFilter = useCallback((filter) => setActiveFilter(filter), []);

  const closeFilter = () => {
    setIsClosing(true);
    setTimeout(() => {
      setActiveFilter(null);
      setIsClosing(false);
    }, 200);
  };

  const canFilterByActiveID = (filter) => {
    const filtersThatCannotBeFilteredByActiveID = [
      FILTER_TYPES.engine,
      FILTER_TYPES.location,
      FILTER_TYPES.price,
    ];
    return filtersThatCannotBeFilteredByActiveID.includes(activeFilter.id)
      ? filter
      : filter[activeFilter.id];
  };

  const buildPreviousSelectedFilterObject = (filter) => {
    const filterKeysArray = Object.keys(filter);
    const appliedFilterValues = filterKeysArray.map(
      (filterKey) => appliedFilters[filterKey]
    );
    return appliedFilterValues.reduce((acc, entry, index) => {
      acc[filterKeysArray[index]] = entry;
      return acc;
    }, {});
  };

  const checkFilterShouldUpdate = (filter) => {
    if (!activeFilter) return true;
    const currentSelectedFilterValue = canFilterByActiveID(filter);
    const previousSelectedFilterValue =
      Object.keys(filter).length > 1
        ? buildPreviousSelectedFilterObject(filter)
        : appliedFilters[activeFilter.id];
    return !isEqual(currentSelectedFilterValue, previousSelectedFilterValue);
  };

  const onFilterUpdate = (filter) => {
    checkFilterShouldUpdate(filter) && onFilterCriteriaUpdate(filter);

    closeFilter();
  };

  const onFiltersClose = useCallback(
    () => EventEmitter.emit(EVENT_TYPES.closeModal, 'filters'),
    []
  );

  const buildFilterSelectedProp = (id, type) => {
    const customSelectedObject = {
      [FILTER_TYPES.location]: () => ({
        location: appliedFilters.location,
        distance: appliedFilters.distance,
        showClickAndCollectOptions: appliedFilters.showClickAndCollectOptions,
        experiments: experiments,
      }),
      [FILTER_TYPES.keywords]: () => ({
        keywords: appliedFilters.keywords,
        features: appliedFilters.features,
      }),
      [FILTER_TYPES.price]: () => ({
        deposit: appliedFilters.deposit,
        maxPrice: appliedFilters.maxPrice,
        minPrice: appliedFilters.minPrice,
        paymentType: appliedFilters.paymentType,
      }),
      [FILTER_TYPES.engine]: () => ({
        maxEngineSize: appliedFilters.maxEngineSize,
        minEngineSize: appliedFilters.minEngineSize,
      }),
      [FILTER_TYPES.make]: () => ({
        makeModelTrimSelection: appliedFilters.makeModel,
      }),
      [FILTER_TYPES.model]: () => ({
        makeModelTrimSelection: appliedFilters.makeModel,
      }),
      [FILTER_TYPES.trim]: () => ({
        makeModelTrimSelection: appliedFilters.makeModel,
      }),
      // Workaround for when a filter uses [''] as default
      // pending for PR 3731 to be discussed
      [FILTER_TYPES.multi]: () =>
        appliedFilters[id].filter((opt) => opt !== EMPTY_VALUE),
    };

    const customAppliedFilter =
      customSelectedObject[type] && customSelectedObject[type]();

    return customAppliedFilter || appliedFilters[id];
  };

  const renderFilterComponentByType = () => {
    if (!activeFilter) return;

    const { id, name, titleSubtext, optionsKey, type } = activeFilter;

    return React.createElement(FILTER_BY_TYPE[type], {
      availableOptions: availableOptions[optionsKey],
      id,
      name,
      titleSubtext,
      onFilterUpdate,
      selected: buildFilterSelectedProp(id, type),
    });
  };

  const memoizedFilter = useMemo(
    () => renderFilterComponentByType(),
    [activeFilter]
  );

  return (
    <>
      <VehicleSearchFilters
        appliedFilters={appliedFilters}
        count={count}
        loading={loading}
        onFilterClick={openFilter}
        onFiltersClose={onFiltersClose}
        onToggleChange={onFilterUpdate}
        resetFilters={resetFilters}
      />
      {activeFilter && (
        <div className={`${isClosing && 'is-closing'}`}>{memoizedFilter}</div>
      )}
    </>
  );
};

export default VehicleSearchFiltersContainer;

VehicleSearchFiltersContainer.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
  availableOptions: PropTypes.object.isRequired,
  count: PropTypes.number,
  experiments: PropTypes.object,
  loading: PropTypes.bool,
  onFilterCriteriaUpdate: PropTypes.func.isRequired,
  resetFilters: PropTypes.func,
};
