import React, { useState } from 'react';
import MakeAndModelSelector from './makeAndModelSelector';
import HomeBudgetSelector from './homeBudgetSelector';
import PropTypes from 'prop-types';
import Analytics from '@lib/analytics';
import { toQueryString } from '@utils';

const HomeFormContainer = ({
  makesAndModels,
  makesDisplay,
  priceOptions,
  selectedMake,
  selectedModel,
  paymentType,
}) => {
  const [makeModel, setMakeModel] = useState({
    make: selectedMake,
    model: selectedModel,
  });

  const [payment, setPayment] = useState({
    deposit: '',
    paymentType: paymentType,
    minPrice: '',
    maxPrice: '',
  });

  const [hasBudgetOpened, setHasBudgetOpened] = useState(false);

  const depositEntered = !!payment.deposit;
  const makeSelected = makeModel.make !== '';
  const modelSelected = makeModel.model !== '' && makeModel.model !== '_all';
  const paymentSelected = payment.minPrice !== '' || payment.maxPrice !== '';

  const handleMakeModelSelect = (event) => {
    event.target.id === 'make'
      ? setMakeModel({ make: event.target.value, model: '_all' })
      : setMakeModel({ ...makeModel, model: event.target.value });
  };

  const handlePriceChange = (paymentDetails) => setPayment(paymentDetails);

  const handleBudgetOpened = () => {
    setHasBudgetOpened(true);
  };

  const shouldSetPaymentTypeToCash = () => {
    return makeModel.make && hasBudgetOpened === false;
  };

  const buildFormParams = () => {
    const params = {
      payment_type: payment.paymentType,
      min_price: payment.minPrice,
      max_price: payment.maxPrice,
      deposit: payment.deposit,
      reset_deposit: !payment.deposit,
    };

    if (makeModel.make) {
      params.make_model = { [makeModel.make]: [makeModel.model] };
    }

    if (shouldSetPaymentTypeToCash()) {
      params.payment_type = 'cash';
    }

    return params;
  };

  const label = () => {
    if (!makeSelected && !paymentSelected) return 'No filters selected';
    if (makeSelected && modelSelected && paymentSelected)
      return 'All filters selected';
    if (makeSelected && paymentSelected) return 'Make and payment selected';
    if (makeSelected && !modelSelected) return 'Make selected';
    if (makeSelected && (makeModel.model !== '' || makeModel.model !== '_all'))
      return 'Make and model selected';
    if (paymentSelected) return 'Payment selected';
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    window.location.href = `/vehicles?${toQueryString(buildFormParams())}`;

    depositEntered;
    Analytics.trackEvent('interaction', {
      action: 'Homepage search',
      label: depositEntered
        ? `Deposit entered - ${payment.deposit}`
        : 'Deposit not entered',
    });
  };

  return (
    <div className="ac-searchmask">
      <form
        action="vehicles"
        aria-label="Search"
        id="home-form"
        onSubmit={onFormSubmit}
      >
        <p className="ac-searchmask__heading">
          Let&apos;s find your{' '}
          <span className="ch-color--ac-yellow">next car...</span>
        </p>
        <div className="ch-row ac-row--compact">
          <MakeAndModelSelector
            handleSelect={handleMakeModelSelect}
            makeModel={makesAndModels}
            makesDisplay={makesDisplay}
            selectedMake={makeModel.make}
            selectedModel={makeModel.model}
          />
          <div className="xs:ch-col--12 sm:ch-col--4 ac-col--compact js-off--hide">
            <HomeBudgetSelector
              onBudgetOpening={handleBudgetOpened}
              onPriceChange={handlePriceChange}
              priceOptions={priceOptions}
              selected={payment}
            />
          </div>

          <div className="xs:ch-col--12 sm:ch-col--2 ac-col--compact">
            <button
              className="ch-btn ch-btn--cta ch-btn--block ch-mt--2 sm:ch-mt--0"
              data-gtm-track="interaction"
              data-gtm-track-action="Homepage search"
              data-gtm-track-label={label()}
              id="search-button"
              tabIndex="0"
              type="submit"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HomeFormContainer;

HomeFormContainer.propTypes = {
  makesAndModels: PropTypes.object,
  makesDisplay: PropTypes.object,
  paymentType: PropTypes.string,
  priceOptions: PropTypes.shape({
    cash: PropTypes.array,
    monthly: PropTypes.array,
  }),
  selectedMake: PropTypes.string,
  selectedModel: PropTypes.string,
};
