import React, { Component } from 'react';
import PropTypes from 'prop-types';
import request from 'superagent';
import Illustration from './illustration';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

export default class FinanceLightbox extends Component {
  static propTypes = {
    financeHeading: PropTypes.string,
    stockReference: PropTypes.string,
    vehicleUrl: PropTypes.string,
  };

  static defaultProps = {
    financeHeading: 'Representative finance example',
  };

  state = {
    error: false,
    illustration: null,
    loading: true,
  };

  componentDidMount() {
    this.getFinanceIllustration();
  }

  getFinanceIllustration = () => {
    request
      .get(`/vehicles/${this.props.stockReference}/hp_finance.json`)
      .then((response) => {
        this.setState({
          error: false,
          illustration: response.body.data,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          error: true,
          loading: false,
        });
      });
  };
  closeModal = () => EventEmitter.emit(EVENT_TYPES.closeModal);

  render() {
    return (
      <div className="ch-modal ch-modal--drawer">
        <div className="ch-modal__header">
          <h1 className="ch-fs--3 ch-mb--0">
            {this.state.illustration &&
              `${this.props.financeHeading} - ${this.state.illustration['Finance type']}`}
          </h1>
          <button
            className="ch-modal__close ch-modal__close--label"
            onClick={this.closeModal}
            type="button"
          >
            Close
          </button>
        </div>
        <div className="ch-modal__body ch-pa--2 ch-bg--grey-1">
          {this.state.loading && (
            <div className="ch-text--center">
              <span className="ac-loading__spinner ac-loading__spinner--small" />
            </div>
          )}
          {this.state.error && (
            <div className="ch-text--center">
              <p>
                Sorry, we couldn’t load the finance information at this time.
              </p>
              <div>
                <button
                  className="ch-btn ch-btn--link"
                  onClick={this.getFinanceIllustration}
                  type="button"
                >
                  Retry
                </button>
                <span> | </span>
                <a className="ch-btn ch-btn--link" href={this.props.vehicleUrl}>
                  View this vehicle
                </a>
              </div>
            </div>
          )}
          {this.state.illustration && (
            <>
              <Illustration financeData={this.state.illustration} />
              <div>
                <a
                  className="ch-btn ch-btn--sm ch-btn--cta ch-mv--2 ch-ph--6 ch-width--12"
                  href={this.props.vehicleUrl}
                >
                  More info
                </a>
                <p className="ch-mb--0 ch-fs--1 ch-lh--body">
                  Arnold Clark Automobiles Limited is authorised and regulated
                  by the Financial Conduct Authority (
                  <a
                    href="https://register.fca.org.uk/s/firm?id=001b000000MfWTWAA3"
                    id="fca-registration-link"
                    rel="nofollow noreferrer"
                    target="_blank"
                    title="View Firm Registration on FCA Website"
                  >
                    308092
                  </a>
                  ) for general insurance and consumer credit purposes. We act
                  as a credit broker sourcing credit to assist with your
                  purchase from a carefully selected panel of lenders. Lenders
                  will pay us a fee for these introductions (
                  <a
                    href="/terms-of-use/panel-of-lenders/"
                    id="finance-disclaimer-link"
                    rel="nofollow"
                    target="_blank"
                    title="Opens Panel of Lenders in a new tab"
                  >
                    click&nbsp;here
                  </a>{' '}
                  for details including our panel of lenders and disclosure
                  statement). Offers subject to status, terms and conditions.
                </p>
              </div>
            </>
          )}
        </div>
        <div className="ch-modal__footer">
          <button
            className="ch-btn ch-width--12"
            onClick={this.closeModal}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
