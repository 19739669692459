interface StockAlertMessageProps {
  url?: string;
}

const StockAlertMessage = ({
  url = '/stock-alerts/new',
}: StockAlertMessageProps): JSX.Element => (
  <div className="ac-result ch-text--center ch-justify-content--center stock-alerts__card ch-pa--4 js-off--hide">
    <span className="stock-alerts__bell ch-mb--2"></span>
    <h3 className="ch-fs--4">Not seeing your perfect car?</h3>
    <p>We&apos;ll let you know as soon as it comes into stock</p>
    <a
      className="ch-btn"
      data-gtm-track="click_cta"
      data-gtm-track-action="Opened stock alerts wizard via SERPs"
      href={url}
    >
      Create a stock alert
    </a>
  </div>
);

export default StockAlertMessage;
