export const FILTER_TYPES = {
  colours: 'colours',
  engine: 'engine',
  keywords: 'keywords',
  location: 'location',
  make: 'make',
  model: 'model',
  trim: 'trim',
  multi: 'multi',
  price: 'price',
  single: 'single',
};

export const FILTERS_SCHEMA = {
  age: {
    id: 'age',
    name: 'Age',
    titleSubtext: '(maximum)',
    optionsKey: 'ageOptions',
    type: FILTER_TYPES.single,
  },
  bodyType: {
    id: 'bodyType',
    name: 'Body types',
    optionsKey: 'bodyTypes',
    type: FILTER_TYPES.multi,
  },
  colour: {
    id: 'colour',
    name: 'Colours',
    optionsKey: 'colours',
    type: FILTER_TYPES.colours,
  },
  doors: {
    id: 'doors',
    name: 'Number of doors',
    optionsKey: 'doorsOptions',
    type: FILTER_TYPES.multi,
  },
  driveTrain: {
    id: 'driveTrain',
    name: 'Drive type',
    optionsKey: 'driveTrains',
    type: FILTER_TYPES.multi,
  },
  engine: {
    id: 'engine',
    name: 'engine size',
    optionsKey: 'engineSizes',
    type: FILTER_TYPES.engine,
  },
  fuelType: {
    id: 'fuelType',
    name: 'Fuel types',
    optionsKey: 'fuelTypes',
    type: FILTER_TYPES.multi,
  },
  keywords: {
    id: 'keywords',
    name: 'Keywords',
    optionsKey: 'featuresOptions',
    type: FILTER_TYPES.keywords,
  },
  location: {
    id: 'location',
    name: 'Location',
    type: FILTER_TYPES.location,
    optionsKey: 'distances',
  },
  make: {
    id: 'makeModel',
    name: 'Makes',
    optionsKey: 'makeModelTrimOptions',
    type: FILTER_TYPES.make,
  },
  model: {
    id: 'makeModel',
    name: 'Models',
    optionsKey: 'makeModelTrimOptions',
    type: FILTER_TYPES.model,
  },
  trim: {
    id: 'makeModel',
    name: 'Trim',
    optionsKey: 'makeModelTrimOptions',
    type: FILTER_TYPES.trim,
  },
  mileage: {
    id: 'mileage',
    name: 'Mileage',
    optionsKey: 'mileageOptions',
    type: FILTER_TYPES.single,
  },
  mpg: {
    id: 'mpg',
    name: 'Fuel consumption / MPG',
    optionsKey: 'mpgOptions',
    type: FILTER_TYPES.single,
  },
  paymentType: { id: 'paymentType' },
  price: {
    id: 'price',
    name: 'Price',
    optionsKey: 'priceOptions',
    type: FILTER_TYPES.price,
  },
  photosOnly: { id: 'photosOnly' },
  roadtaxCost: {
    id: 'roadtaxCost',
    name: 'Road tax',
    titleSubtext: '(per year)',
    optionsKey: 'roadtaxOptions',
    type: FILTER_TYPES.single,
  },
  searchType: {
    id: 'searchType',
    name: 'Car type',
    optionsKey: 'searchTypes',
    type: FILTER_TYPES.single,
  },
  seats: {
    id: 'seats',
    name: 'Number of seats',
    optionsKey: 'seatsOptions',
    type: FILTER_TYPES.multi,
  },
  transmission: {
    id: 'transmission',
    name: 'Gearbox',
    optionsKey: 'gearboxOptions',
    type: FILTER_TYPES.single,
  },
  unreservedOnly: { id: 'unreservedOnly' },
};
