import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterContainer } from '@components/search_filters/units';
import { useMultiSelect } from '../hooks';
import FeatureOption from '../units/featureOption';

const EMPTY_VALUE = '';

const Keywords = ({ availableOptions, id, name, onFilterUpdate, selected }) => {
  const initialValue = selected.keywords || EMPTY_VALUE;
  const [inputValue, setInputValue] = useState(initialValue);

  const { selectedOptions, handleChange } = useMultiSelect(selected.features);

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilterUpdate({ [id]: inputValue, ['features']: selectedOptions });
  };

  const renderAvailableOptions = () => {
    return availableOptions.map(([displayLabel, value]) => {
      return (
        <FeatureOption
          key={`${id}_all`}
          name="features"
          onChange={handleChange}
          selected={selectedOptions.includes(value)}
          value={displayLabel}
        />
      );
    });
  };

  return (
    <FilterContainer handleClick={handleSubmit} title={name}>
      <form onSubmit={handleSubmit}>
        <h4 className="ch-mt--2">What are you looking for?</h4>
        <input
          autoFocus
          className="ch-form__control"
          name={id}
          onChange={(evt) => setInputValue(evt.target.value)}
          placeholder="Start typing..."
          type="text"
          value={inputValue}
        />
        <button className="ch-reader" type="submit">
          Done
        </button>
      </form>
      <h4 className="ch-mt--5">Popular searches</h4>
      {renderAvailableOptions()}
    </FilterContainer>
  );
};

export default Keywords;

Keywords.propTypes = {
  availableOptions: PropTypes.array,
  id: PropTypes.string,
  name: PropTypes.string,
  onFilterUpdate: PropTypes.func,
  selected: PropTypes.shape({
    keywords: PropTypes.string,
    features: PropTypes.array,
  }),
};
