import { useState } from 'react';
import useShortlist from './shortlistStore';

interface ShortlistAllButtonProps {
  stockReferences: string[];
}

const ShortlistAllButton = ({
  stockReferences,
}: ShortlistAllButtonProps): JSX.Element | null => {
  const {
    store: { add, shortlist },
  } = useShortlist();

  const [addAllClicked, setAddAllClicked] = useState<boolean>(false);

  const addAllToShortlist = () => {
    add(stockReferences);
    setAddAllClicked(true);
  };

  // if a vehicle card is de-shortlisted after the "Add all" button is clicked
  // we want to hide the success alert
  const shortlistEdited =
    addAllClicked &&
    stockReferences.some((stockRef) => !shortlist.includes(stockRef));

  if (!addAllClicked) {
    return (
      <button
        className="js-off--hide ch-btn ch-btn--primary"
        data-gtm-track="click_cta"
        onClick={addAllToShortlist}
        type="button"
      >
        Add all vehicles to your shortlist
      </button>
    );
  }

  return shortlistEdited ? null : (
    <span className="ch-width--12 sl:ch-width--7 md:ch-width--4 ch-alert ch-alert--icon ch-alert--success">
      {stockReferences.length} vehicles added to your shortlist
    </span>
  );
};

export default ShortlistAllButton;
