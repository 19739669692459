import React from 'react';

interface NoResultsProps {
  stockAlertMessage: React.ReactNode;
}

const NoResults = ({ stockAlertMessage }: NoResultsProps): JSX.Element => (
  <div className="ch-text--center ac-noresults">
    <h2>Sorry! No results found</h2>
    <p>We couldn’t find any cars matching your search</p>
    <p>
      Try removing some filters or <a href="/vehicles">reset your search</a> to
      start again.
    </p>
    <div className="ch-mt--4 ch-display--flex ch-justify-content--center js-off--hide">
      {stockAlertMessage}
    </div>
  </div>
);

export default NoResults;
