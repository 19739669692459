import request from 'superagent';
import type { StockReferences } from '@components/shortlist/shortlistStore';
import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';
import { useAccessToken } from '@arnoldclark/use-access-token';
import Auth from '../auth';

export enum CounterOperation {
  increment = 'increment',
  decrement = 'decrement',
}

const { isNativeApp } = acMobileFeatures();

const getAccessTokenSilently = () =>
  new Auth()
    .initializeAuthClient(window.authOptions)
    .then((client) => client.getTokenSilently());

// the linter thinks this is a React hook, it's not
// eslint-disable-next-line react-hooks/rules-of-hooks
const { getAuthHeaders, fetchWithAuthRetry } = useAccessToken(
  isNativeApp(),
  getAccessTokenSilently
);

const getCSRFToken = (): string =>
  document.querySelector("meta[name='csrf-token']")?.getAttribute('content') ||
  '';

const csrfHeader = () => ({ 'X-CSRF-Token': getCSRFToken() });

export async function getShortlist(): Promise<StockReferences> {
  const headers = await getAuthHeaders();

  return fetchWithAuthRetry('/api/shortlist', { headers }).then((res) =>
    res.json()
  );
}

export async function mergeShortlist(
  stockReferences: StockReferences
): Promise<StockReferences> {
  const headers = await getAuthHeaders({
    ...csrfHeader(),
    'Content-Type': 'application/json',
  });

  return fetchWithAuthRetry('/api/shortlist/merge', {
    body: JSON.stringify({ stockReferences }),
    headers,
    method: 'PUT',
  }).then((res) => res.json());
}

export async function updateShortlist(
  stockReferences: StockReferences
): Promise<Response> {
  const headers = await getAuthHeaders({
    ...csrfHeader(),
    'Content-Type': 'application/json',
  });
  return fetchWithAuthRetry('/api/shortlist/replace', {
    body: JSON.stringify({ stockReferences }),
    headers,
    method: 'PUT',
  });
}

export const updateShortlistCount = (
  stockReferences: StockReferences,
  operation: CounterOperation
): void => {
  const stockRefArray = Array.isArray(stockReferences)
    ? stockReferences
    : [stockReferences];
  request
    .post('/shortlisted/count')
    .set(csrfHeader())
    .send({
      operation,
      stock_references: stockRefArray,
    })
    .end((err, res) => {
      if (err || !res.ok) {
        return;
      }
    });
};
